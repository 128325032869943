*{
  -webkit-tap-highlight-color: transparent;
  font-family: Montserrat, sans-serif !important;
}

html {
  height: 100%;
}

body {
  font-family: $body-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  background: $body-bg;
  color: $body-font-color;
  backface-visibility: hidden;
  overflow-y: scroll;
  line-height: $body-line-height;

  @include direction {
    text-align: $inline-start;
    direction: $direction;
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
}

#root {
  height: 100%;
}

svg {
  display: block;
}

a {
  color: $link-color;
}

a:hover {
  color: $main-theme-color-light;
  text-decoration: none;
}



h1 {
  text-align: center;
  @if ($h1-font-family != null) {
    font-family: $h1-font-family;
  }
  @if ($h1-font-size != null) {
    font-size: $h1-font-size;
  }
  @if ($h1-font-weight != null) {
    font-weight: $h1-font-weight;
  }
  @if ($h1-font-style != null) {
    font-style: $h1-font-style;
  }

  @media screen and (max-width: 425px) {
    font-size: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

h2 {
  @if ($h2-font-family != null) {
    font-family: $h2-font-family;
  }
  @if ($h2-font-size != null) {
    font-size: $h2-font-size;
  }
  @if ($h2-font-weight != null) {
    font-weight: $h2-font-weight;
  }
  @if ($h2-font-style != null) {
    font-style: $h2-font-style;
  }
}

h3 {
  @if ($h3-font-family != null) {
    font-family: $h3-font-family;
  }
  @if ($h3-font-size != null) {
    font-size: $h3-font-size;
  }
  @if ($h3-font-weight != null) {
    font-weight: $h3-font-weight;
  }
  @if ($h3-font-style != null) {
    font-style: $h3-font-style;
  }
}

h4 {
  @if ($h4-font-family != null) {
    font-family: $h4-font-family;
  }
  @if ($h4-font-size != null) {
    font-size: $h4-font-size;
  }
  @if ($h4-font-weight != null) {
    font-weight: $h4-font-weight;
  }
  @if ($h4-font-style != null) {
    font-style: $h4-font-style;
  }
}

h5 {
  @if ($h5-font-family != null) {
    font-family: $h5-font-family;
  }
  @if ($h5-font-size != null) {
    font-size: $h5-font-size;
  }
  @if ($h5-font-weight != null) {
    font-weight: $h5-font-weight;
  }
  @if ($h5-font-style != null) {
    font-style: $h5-font-style;
  }
}

h6 {
  @if ($h6-font-family != null) {
    font-family: $h6-font-family;
  }
  @if ($h6-font-size != null) {
    font-size: $h6-font-size;
  }
  @if ($h6-font-weight != null) {
    font-weight: $h6-font-weight;
  }
  @if ($h6-font-style != null) {
    font-style: $h6-font-style;
  }
}


.grecaptcha-badge{
  display: none;
}

.slick-initialized {
  height: 100%;

  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;
    }
  }
}

.slick-slide {
  & > div {
    height: 100%;
  }
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -20px;
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: '←';
}

[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: -20px;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: '→';
}

[dir='rtl'] .slick-next:before {
  content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}